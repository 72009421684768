import {useCreateInterviewAndTimestampMutation} from 'src/graphql-generated'
import useViewer from 'src/hooks/useViewer'

const useTrackInterviewInteraction = () => {
  const {loggedIn} = useViewer()
  const [createInterviewAndTimestamp] = useCreateInterviewAndTimestampMutation()

  const trackInterviewInteraction = (eventType: string) => {
    if (!loggedIn) {
      return
    }

    const languageInput = document.querySelector(
      '.language__input:checked'
    ) as HTMLInputElement| null

    const selectedLanguage = languageInput?.value || 'No language selected'
    console.log('Selected Language:', selectedLanguage)
    
    createInterviewAndTimestamp({variables: {eventType}})
  }

  return trackInterviewInteraction
}

export default useTrackInterviewInteraction
